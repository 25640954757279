import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { faReact, faVuejs, faAngular, faJava, faJs, faCuttlefish  } from '@fortawesome/free-brands-svg-icons'

import './Portfolio.css';
import 'react-vertical-timeline-component/style.min.css';

export default function Portfolio() {


  return (
    <div className="portfolio-main">
      <h1>Projects</h1>
      <hr />
      <Scrollbars>
        <VerticalTimeline
          className="vert-line"
        >
          {
            projectData.map( proj => (
              <VerticalTimelineElement
                className="vertical-timeline-element"
                contentStyle={{ background: '#00000040', color: '#FFF', textShadow: "2px 2px #000000", }}
                contentArrowStyle={{ borderRight: '7px solid #00000040' }}
                iconStyle={{ background: '#00000070', color: '#fff' }}
                icon={ <FontAwesomeIcon icon={ proj.icon } /> }
                date={ proj.dates }
              >
                {Card( proj.title, proj.thumbnail, proj.summary )}
              </VerticalTimelineElement>
            ))
          }
        </VerticalTimeline>
      </Scrollbars>
    </div>
  )
}

const Card = ( title, image, summary ) => {

  return (
    <div className="card">
      <div className="thumbnail">
        <img src={ require( `./Assets/${ image }` ) } alt="thumbnail" />
        <div className="title-summary">
          <h3>{ title }</h3>
          <hr />
          <p>{ summary }</p>
        </div>
      </div>
    </div>
  )
}

const projectData = [
  {
    id: 10,
    title: "ODIN - Operational Environment Data Integration Network",
    summary: "Added new features and maintained existing web application",
    thumbnail: "ODIN-thumb.png",
    icon: faReact,
    dates: "Jun 2020 - Current",
  },
  {
    id: 9,
    title: "TIMS - Traffic Integration Messaging System",
    summary: "Refactored Angular.js to React.js while enabling new features",
    thumbnail: "TIMS-thumb.png",
    icon: faReact,
    dates: "January 2020 - Current",
  },
  {
    id: 11,
    title: "Bubble Sort Analysis",
    summary: "Iterative versus recursive analysis and determining Big-Θ",
    thumbnail: "BubbleSortAnalysis-thumb.png",
    icon: faJava,
    dates: "December 2019"
  },
  {
    id: 6,
    title: "Food 4 Friends",
    summary: "Capstone web application written in JavaScript using Vue.js framework",
    thumbnail: "Food4Friends-thumb.png",
    icon: faVuejs,
    dates: "October - December 2019",
  },
  {
    id: 5,
    title: "Sea Port Program",
    summary: "Multi-threaded stochastic simulation of a sea port written in Java",
    thumbnail: "SeaPortProgram-thumb.png",
    icon: faJava,
    dates: "January - March 2019"
  },
  {
    id: 0,
    title: "WebGL Project",
    summary: "JavaScript page using WebGL",
    thumbnail: "WebGL-thumb.png",
    icon: faJs,
    dates: "July 2018",
  },
  {
    id: 1,
    title: "Three.js Project",
    summary: "JavaScript page using Three.js and WebGL",
    thumbnail: "Three-js-thumb.png",
    icon: faJs,
    dates: "July 2018",
  },
  {
    id: 4,
    title: "Parsing Program",
    summary: "Java application that reads file and builds GUI based on syntax",
    thumbnail: "ParsingProgram-thumb.png",
    icon: faJava,
    dates: "March 2018",
  },
  {
    id: 8,
    title: "Expression Evaluator",
    summary: "C++ application that parsed expressions and solved",
    thumbnail: "ExpressionEvaluator-thumb.png",
    icon: faCuttlefish,
    dates: "March 2018"
  },
  {
    id: 3,
    title: "Postfix to Infix",
    summary: "Java application that parsed postfix expression and converted to infix",
    thumbnail: "PostfixToInfix-thumb.png",
    icon: faJava,
    dates: "September 2017",
  },
  {
    id: 2,
    title: "Infix Expression Evaluator",
    summary: "Java application that parsed an equation and solved",
    thumbnail: "InfixExpression-thumb.png",
    icon: faJava,
    dates: "September 2017",
  },
  {
    id: 7,
    title: "Fibonacci Efficiency",
    summary: "Java application that solved <code>2 * (f(n-2) + f(n-1)) and provided efficiency for iterative versus recursive",
    thumbnail: "FibonacciEfficiency-thumb.png",
    icon: faJava,
    dates: "February 2017"
  },  
];