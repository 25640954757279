const backgroundArray = [
  require( './Assets/1.jpg' ), 
  require( './Assets/2.jpg' ), 
  require( './Assets/3.jpg' ), 
  require( './Assets/4.jpg' ), 
  require( './Assets/5.jpg' ), 
  require( './Assets/6.jpg' ), 
  require( './Assets/7.jpg' ), 
  require( './Assets/8.jpg' ), 
  require( './Assets/9.jpg' ), 
  require( './Assets/10.jpg' ),
  require( './Assets/11.jpg' ),
  require( './Assets/12.jpg' ),
  require( './Assets/13.jpg' ),
  require( './Assets/14.jpg' ),
  require( './Assets/15.jpg' ),
  require( './Assets/16.jpg' ),
  require( './Assets/17.jpg' ),
  require( './Assets/18.jpg' ),
  require( './Assets/19.jpg' ),
  require( './Assets/20.jpg' ),
];

/**
 * Create a style object with a random background image
 */
const RandomBG = () => {
  const randomBG = Math.floor( Math.random() * backgroundArray.length );
  return {
    backgroundImage: `url(${ backgroundArray[ randomBG ] })`,
  };
};

export default RandomBG;