import React, { useState, useEffect } from 'react';

import RandomBG from '../../Utils/RandomBG/RandomBG';

import './Layout.css';

export default function Layout( props ) {
  const [ bgImage, setBgImage ] = useState();

  useEffect(() => {
    const image = RandomBG();
    setBgImage( image );
  }, []);

  return (
    <div className="layout" style={ bgImage }>
      { props.children }
    </div>
  )
}
