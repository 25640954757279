import React from 'react';

import letterM from '../../Images/LetterM.svg';

import './Home.css';

export default function Home() {

  return (
    <div className="mainText">
      <img src={letterM} className="letterM" alt="letterM" />
      <h1>Welcome to the Markowski Pages</h1>
      <p>There is fun to be done</p>
    </div>
  )
}
