import React from 'react'

import './Footer.css';

export default function Footer() {
  return (
    <div className="footer">
      <code><a href="mailto:david.markowski@hookwitz.com">Webmaster</a> &copy; 2020</code>
    </div>
  )
}
