// Dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// Pages
import Layout from './Pages/Layout/Layout';
import Navigation from './Components/Navigation/Navigation';
import Home from './Pages/Home/Home';
import Portfolio from './Pages/Porfolio/Portfolio';
import Footer from './Components/Footer/Footer';

// Assests
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Layout>
      <BrowserRouter>
        <Navigation />
        <Switch>
          <Route exact path="/">
            <Redirect to="/Home" />
          </Route>
          <Route exact path="/Home" component={ Home } />
          <Route exact path="/Portfolio" component={ Portfolio } />
        </Switch>
        <Footer />
      </BrowserRouter>
    </Layout>
  </React.StrictMode>,
  document.getElementById('root')
);
