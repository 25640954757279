import React from 'react'
import { NavLink } from 'react-router-dom'

import './Navigation.css'

export default function Navigation() {
  /* const menuTitles = [
    'Home',
    'Portfolio',
    'Places to Be',
    'Words to Read',
  ]; */

  return (
    <nav>
      <NavLink
        to={ '/Home' }
        className="navButton"
        isActive={( match, location ) => {
          if ( match ) return true;
          if ( location.pathname === '/' ) return true;
          return false;
        }}
      >
        Home
      </NavLink>
      
      <NavLink
        to={ '/Portfolio' }
        className="navButton"
      >
        Portfolio
      </NavLink>

      <NavLink
        to={ '/Places' }
        className="disabledButton"
      >
        Places to Go
      </NavLink>

      <NavLink
        to={ '/Words' }
        className="disabledButton"
      >
        Words to Read
      </NavLink>
    </nav>
  )
}

/* const Button = ( title ) => {
  // set state for active
  const [ active, setActive ] = useState( false );
  // add function to set button to active
  const handleClick = () => {
    setActive( !active );
  }
  // return elemet
  return (
    <div className={ active ? 'navButton-active' : 'navButton' } onClick={ handleClick }>
      { title }
    </div>
  )
} */
